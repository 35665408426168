<template>
  <b-modal
    id="modal-error-store-order"
    hide-footer
    hide-header
    centered
  >
    <b-row class="justify-content-center mb-1 pt-1">
      <img src="https://storage.googleapis.com/komerce/assets/icons/warning.svg">
    </b-row>
    <b-row class="justify-content-center text-center mb-1">
      <span class="text-black px-4">
        Maaf ada sedikit kendala koneksi. Silahkan untuk refresh halaman
      </span>
    </b-row>
    <b-row class="justify-content-center pb-1">
      <b-button
        class="btn-icon"
        variant="primary"
        @click="refreshPage"
      >
        Refresh
      </b-button>
    </b-row>
  </b-modal>
</template>
<script>
export default {
  methods: {
    refreshPage() {
      window.location.reload()
    },
  },
}
</script>
