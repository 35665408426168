import { render, staticRenderFns } from "./AddOrder.html?vue&type=template&id=d571d1d0&"
import script from "./AddOrder.js?vue&type=script&lang=js&"
export * from "./AddOrder.js?vue&type=script&lang=js&"
import style0 from "./AddOrder.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports