<template>
  <b-modal
    id="modal-bundling"
    hide-footer
    size="lg"
    centered
  >
    <div
      v-if="variantChildModal !== null"
      class="h-min"
    >
      <div class="rounded-t">
        <b-table
          :items="variantChildModal.bundle_component"
          :fields="fields"
        >
          <template #cell(product_name)="data">
            <div class="flex gap-[16px] items-start">
              <img
                :src="data.item.product_image[0] ? data.item.product_image[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                alt="product"
                width="52"
                height="52"
              >
              <div class="space-y-[4px]">
                <div class="text-[16px] text-black">
                  {{ data.item.product_name }}
                </div>
                <div class="text-[14px]">
                  SKU: {{ data.item.sku }}
                </div>
                <div
                  v-if="option_id === data.index || data.item.is_variant === '0' ? data.item.stock <= 0 : false"
                  class="text-danger"
                >
                  Stock Habis
                </div>
              </div>
            </div>
          </template>
          <template #cell(variant)="data">
            <div v-if="data.item.is_variant === '1'">
              <div
                v-for="(variant, indexVariant) in data.item.variantSelected"
                :key="variant.variant_id"
              >
                <div>Tipe {{ variant.variant_name }}</div>
                <div class="d-flex my-1">
                  <b-button
                    v-for="typeVariant in variant.variant_option"
                    :key="typeVariant.option_id"
                    :variant="typeVariant.is_active ? 'primary' : 'outline-primary'"
                    :disabled="data.item.variantSelected.length === 1 ? typeVariant.stock === 0 : typeVariant.is_disabled"
                    size="sm"
                    class="mr-1"
                    @click="selectVariant(typeVariant, data.index, indexVariant)"
                  >
                    {{ typeVariant.option_name }}
                  </b-button>
                </div>
              </div>
            </div>
            <div v-else>
              -
            </div>
          </template>
        </b-table>
      </div>
      <b-button
        class="float-right mb-1"
        :variant="getDisabled ? 'secondary' : 'primary'"
        :disabled="getDisabled"
        @click="submit"
      >
        Pilih
      </b-button>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: {
    bundlingComponent: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      variantChildModal: JSON.parse(JSON.stringify(this.bundlingComponent)),
      fields: [
        {
          key: 'product_name',
          label: 'Nama Produk',
        },
        {
          key: 'variant',
          label: 'Variasi',
        },
      ],
      dataForParent: [],
      option_id: null,
    }
  },
  computed: {
    getDisabled() {
      let result = true
      if (this.variantChildModal.bundle_component !== undefined) {
        result = this.variantChildModal.bundle_component.every(itemSelected => {
          if (itemSelected.is_variant === '1') {
            return itemSelected.variantSelected[itemSelected.variantSelected.length - 1].variant_option.some(itemOption => itemOption.is_active)
          }
          return true
        })
      }
      return !result
    },
  },
  watch: {
    bundlingComponent(newVal) {
      this.variantChildModal = JSON.parse(JSON.stringify(newVal))
    },
    dataForParent(newVal) {
      this.$emit('update:dataForParent', newVal)
    },
  },
  methods: {
    selectVariant(clickedVariant, index, indexVariant) {
      this.variantChildModal.bundle_component.forEach((itemSelected, indexSelected) => {
        if (indexSelected === index) {
          let sameParentId = []
          itemSelected.variant.forEach((itemVariant, idxVariant) => {
            const sameParentIdTemporary = itemVariant.variant_option.filter(item => item.option_parent === clickedVariant.option_id)
            if (sameParentIdTemporary.length > 0) {
              sameParentId = sameParentIdTemporary
            }
          })
          itemSelected.variantSelected.forEach((itemVariantSelected, idxVariantSelected) => {
            itemVariantSelected.variant_option.forEach((option, idxVariantOption) => {
              if (idxVariantSelected === indexVariant) {
                this.$set(option, 'is_active', option.option_id === clickedVariant.option_id)
              }
              if (sameParentId.length !== 0 && sameParentId[0].variant_id === itemVariantSelected.variant_id) {
                if (indexVariant === itemSelected.variantSelected.length - 2) {
                  const findParentIdIsActiveTemporary = itemSelected.variantSelected[indexVariant].variant_option.find(item => item.is_active === true)
                  if (findParentIdIsActiveTemporary) {
                    const findSameParent = itemSelected.product_variant.filter(item => item.parent === findParentIdIsActiveTemporary.option_id)
                    const findStock = findSameParent.find(item => item.name === option.option_name)
                    if (findStock) {
                      const updatedOption = {
                        is_active: false,
                        is_disabled: findStock.stock === 0,
                        option_id: findStock.options_id,
                        option_parent: findStock.parent,
                        option_name: findStock.name,
                        price: findStock.price,
                        stock: findStock.stock,
                      }
                      this.$set(itemVariantSelected.variant_option, idxVariantOption, updatedOption)
                    }
                  }
                } else {
                  this.$set(itemVariantSelected, 'variant_option', sameParentId)
                }
              }
            })
          })
        }
      })
    },
    submit() {
      const dataTableShow = {}
      this.variantChildModal.bundle_component.forEach((itemModal, indexModal) => {
        const dataTableComp = {}
        if (itemModal.is_variant === '1') {
          itemModal.variantSelected.forEach((item, index) => {
            item.variant_option.forEach(option => {
              if (option.is_active) {
                dataTableComp.stock = option.stock
                dataTableComp.quantity = 1
                dataTableComp.option_id = option.option_id
                dataTableComp.price = option.price
                dataTableComp.is_active = true
                dataTableComp.subtotal = option.price * dataTableComp.quantity
                if (index === 0) {
                  dataTableComp.variant_name = `${option.option_name}`
                } else {
                  dataTableComp.variant_name += `, ${option.option_name}`
                }
              }
            })
          })
        } else {
          dataTableComp.stock = itemModal.stock
          dataTableComp.quantity = 1
          dataTableComp.option_id = itemModal.id
          dataTableComp.price = 0
          dataTableComp.is_active = true
          dataTableComp.subtotal = 0 * dataTableComp.quantity
          dataTableComp.variant_name = '-'
        }
        Object.assign(itemModal, { dataTableComp })
        dataTableShow.stock = dataTableShow.stock < itemModal.dataTableComp.stock ? dataTableShow.stock : itemModal.dataTableComp.stock
        dataTableShow.quantity = 1
        dataTableShow.price = this.variantChildModal.price
        dataTableShow.is_active = true
        dataTableShow.subtotal = this.variantChildModal.price
        dataTableShow.variant_name = 'bundling'
        if (indexModal === 0) {
          dataTableShow.variant_id = itemModal.is_variant === '1' ? itemModal.dataTableComp.option_id : 0
          dataTableShow.variant_complete_name = itemModal.dataTableComp.variant_name
        } else {
          dataTableShow.variant_id += itemModal.is_variant === '1' ? itemModal.dataTableComp.option_id : 0
          dataTableShow.variant_complete_name += itemModal.dataTableComp.variant_name
        }
      })
      Object.assign(this.variantChildModal, { dataTableShow })
      this.variantChildModal.variantSubmit = this.variantChildModal.dataTableShow.is_active
      this.variantChildModal.variant_name = this.variantChildModal.dataTableShow.variant_name
      this.variantChildModal.variant_id = this.variantChildModal.dataTableShow.variant_id
      this.variantChildModal.stockAvailable = this.variantChildModal.dataTableShow.stock - 1
      this.variantChildModal.stock = this.handleStock()

      this.dataForParent = this.variantChildModal
      this.$bvModal.hide('modal-bundling')
    },
    handleStock() {
      let result
      const optionsIdCounts = {}

      this.variantChildModal.bundle_component.forEach(item => {
        const optionsId = item.dataTableComp.option_id
        optionsIdCounts[optionsId] = (optionsIdCounts[optionsId] || 0) + 1
      })

      if (Object.keys(optionsIdCounts).length > 0) {
        const collectStock = []
        Object.keys(optionsIdCounts).forEach(key => {
          const sameOptionsId = this.variantChildModal.bundle_component.find(item => item.dataTableComp.option_id === Number(key))
          const isStock = sameOptionsId.dataTableComp.stock / optionsIdCounts[key]
          collectStock.push(isStock)
        })
        result = Math.floor(Math.min(...collectStock)) - 1
      } else {
        result = this.variantChildModal.dataTableShow.stock - 1
      }

      return result
    },
  },
}
</script>
